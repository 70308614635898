export const environment = {
  production: false,
  apiURL: 'https://api.tecnologiaasap.com.br',
  baseURL: 'https://api.tecnologiaasap.com.br',
  filestack: {
    key: 'AD5oDjsJaTJOzLe1Llj9mz'
  },
  pusher: {
    key: 'eaf41725f3b027147953',
  },
  firebase: {
    apiKey: "AIzaSyDpcFrHt_pPei3MkccLM011LaeCrdrI8Vo",
    authDomain: "asta-e860d.firebaseapp.com",
    databaseURL: "https://asta-e860d.firebaseio.com",
    projectId: "asta-e860d",
    storageBucket: "asta-e860d.appspot.com",
    messagingSenderId: "576204749170"
  }
};
