import { NgModule }from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';

import { OwlModule } from 'ngx-owl-carousel';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { TermsComponent } from './terms/terms.component';
import { TopMobileComponent } from './top-mobile/top-mobile.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NavbarMobileComponent } from './navbar-mobile/navbar-mobile.component';
import { SideMenuComponent } from './sidemenu/sidemenu.component';

// PORTAL
import { BoxContributorsComponent } from './portal/contributors/box-contributors.component';
import { BoxSpotlightsComponent } from './portal/spotlights/box-spotlights.component';
import { PortalFooterComponent } from './portal/footer/portal-footer.component';

// MARKET
import { MarketFooterComponent } from './market/footer/market-footer.component';
import { AsapImagePipeModule } from 'asap-image-pipe';
import { AsapUploadModule } from 'asap-upload';
import { CurrencyMaskConfig, NgxCurrencyModule } from 'ngx-currency';
import { CURRENCY_MASK_CONFIG } from 'ngx-currency/src/currency-mask.config';
import { AsapSkeletonModule } from 'asap-skeleton';
import { AsapCheckoutModule } from 'asap-checkout';
import { MessengerNotificationComponent } from './messenger-notification/messenger-notification.component';

export const customCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$',
  suffix: '',
  thousands: '.',
  nullable: true
};

export const maskCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$',
  suffix: '',
  thousands: '.',
  nullable: false
};


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    OwlModule,
    Ng2ImgMaxModule,
    InfiniteScrollModule,
    AsapImagePipeModule,
    AsapUploadModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    AsapSkeletonModule,
    AsapCheckoutModule
  ],
  declarations: [
    TermsComponent,
    TopMobileComponent,
    NavbarComponent,
    NavbarMobileComponent,
    SideMenuComponent,
    BoxContributorsComponent,
    BoxSpotlightsComponent,
    PortalFooterComponent,
    MarketFooterComponent,
    MessengerNotificationComponent
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    OwlModule,
    TermsComponent,
    TopMobileComponent,
    NavbarComponent,
    NavbarMobileComponent,
    SideMenuComponent,
    BoxContributorsComponent,
    BoxSpotlightsComponent,
    PortalFooterComponent,
    MarketFooterComponent,
    AsapImagePipeModule,
    AsapUploadModule,
    NgxCurrencyModule,
    MessengerNotificationComponent,
    AsapCheckoutModule
  ],
  providers: [
    {provide: CURRENCY_MASK_CONFIG, useValue: maskCurrencyMaskConfig}
  ]
})

export class SharedModule { }
