
import { Routes, RouterModule } from '@angular/router';

import { SchoolComponent } from './school/school.component';
import { MyPageComponent } from './my-page/my-page.component';
import { MessengerComponent } from './messenger/messenger.component';
import { PaymentComponent } from './payment/payment.component';


export const AppRoutes: Routes = [
  {
    path: '',
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: 'portal',
    loadChildren: './portal/portal.module#PortalModule'
  },
  {
    path: 'market',
    loadChildren: './market/market.module#MarketModule'
  },
  {
    path: '',
    loadChildren: './my-store/my-store.module#MyStoreModule'
  },
  {
    path: '',
    loadChildren: './my-page/my-purchases/my-purchases.module#MyPurchasesModule'
  },
  {
    path: '',
    component: SchoolComponent,
    children: [
      {
        path: 'school',
        loadChildren: './school/home/school-home.module#SchoolHomeModule'
      },
      {
        path: 'school/level',
        loadChildren: './school/level/school-level.module#SchoolLevelModule'
      }
    ]
  },
  {
    path: '',
    component: MyPageComponent,
    children: [
      {
        path: 'my-page',
        loadChildren: './my-page/home/my-page-home.module#MyPageHomeModule'
      }
    ]
  },
  {
    path: '',
    component: MessengerComponent,
    children: [
      {
        path: 'messenger',
        loadChildren: './messenger/home/messenger-home.module#MessengerHomeModule'
      }
    ]
  },
  {
    path: '',
    component: PaymentComponent,
    children: [
      {
        path: 'payment',
        loadChildren: './payment/home/payment-home.module#PaymentHomeModule'
      }
    ]
  },
  {
    path: '',
    component: SchoolComponent,
    children: [
      {
        path: 'help-desk',
        loadChildren: './help-desk/help-desk.module#HelpDeskModule'
      }
    ]
  },
];
