import { Component } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-school',
  templateUrl: './school.component.html'
})

export class SchoolComponent {

}
