import { Component } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
    selector: 'app-box-spotlights',
    templateUrl: './box-spotlights.component.html',
    providers: [ ProductService, StoreService ]
})

export class BoxSpotlightsComponent {

    public mainProducts;
    public mainStores;
    public loading = true;

    constructor(
        public productService: ProductService,
        public storeService: StoreService
    ) {
        this.productService.mainProducts().subscribe((response:any) => {
            this.mainProducts = response.data;
            this.loading = false;
        });
        this.storeService.mainStores().subscribe((response:any) => {
            this.mainStores = response;
            this.loading = false;
        });
    }

}
