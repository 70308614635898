import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'asap-crud';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html'
})

export class NavbarComponent {

    constructor(
        public global: Global,
        public router: Router
    ) {

    }

    public doLogout() {
        this.global.notification.success("Logout","Logout efetuado com sucesso!");
        sessionStorage.clear();
        this.router.navigate(['/portal']);
    }

}
