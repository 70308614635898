import { Component, OnInit } from '@angular/core';
import { Global } from 'asap-crud';

declare const $: any;

@Component({
    selector: 'app-sidemenu',
    templateUrl: './sidemenu.component.html'
})

export class SideMenuComponent implements OnInit {

    constructor(
        public global: Global
    ) {
    }

    ngOnInit() {
        $(document).ready(() => {

          $('.menu li a').on('click', () => {
              $('.menu li a.current').removeClass('current');
              $(this).addClass('current');
          });

          $('.sidemenuCollapse').on('click', () => {
              $('.sidemenu, .content, .overlay').toggleClass('active');
          });

          $('.overlay').on('click', () => {
              $('.sidemenu, .content, .overlay').removeClass('active');
          });

          $('.components').on('click', () => {
              $('.sidemenu, .content, .overlay').removeClass('active');
          });

          $('.navbar-brand').on('click', () => {
              $('.sidemenu, .content, .overlay').removeClass('active');
          });

        });
    }

}
