import { Component } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-messenger',
  template: '<router-outlet></router-outlet>'
})

export class MessengerComponent {

}
