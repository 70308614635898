import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler, Injectable, Component } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { TranslateService } from '@ngx-translate/core';

Sentry.init({
    dsn: "https://77f707185de3440cb86e433a8272960f@sentry.io/1490924"
});

Sentry.configureScope((scope) => {
    if (sessionStorage.getItem('loggedUser')) {
        let user: any = JSON.parse(sessionStorage.getItem('loggedUser'));
        scope.setUser({ "email": user.data.email, 'name': user.data.name, 'id': user.data.id });
        scope.setExtra("Branch", "Workplace");
    }
})

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() { }
    handleError(error) {
        const eventId = Sentry.captureException(error.originalError || error);
        Sentry.showReportDialog({ eventId });
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule],
    providers: [{ provide: ErrorHandler, useClass: SentryErrorHandler }],
    bootstrap: [AppComponent]
})

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})

export class AppComponent {

    constructor(private translate: TranslateService) {
        translate.setDefaultLang('pt-br');
    }

    public scrollTop(event) {
        window.scroll(0, 0);
    }

}
