import { Component } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-my-page',
  templateUrl: './my-page.component.html'
})

export class MyPageComponent {

}
