import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Router } from '@angular/router';
import { CommonModule, registerLocaleData } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { SchoolComponent } from './school/school.component';
import { MyPageComponent } from './my-page/my-page.component';
import { MessengerComponent } from './messenger/messenger.component';
import { PaymentComponent } from './payment/payment.component';
import { SimpleNotificationsModule, NotificationsService } from 'angular2-notifications';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ClientInterceptor } from './@core/interceptors/client-interceptor';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgxCurrencyModule, CurrencyMaskConfig } from 'ngx-currency';
import { MomentModule } from 'ngx-moment';
import localePt from '@angular/common/locales/pt';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { AsapCrudModule } from 'asap-crud';
import { AsapViewerModule } from 'asap-viewer';
import { AsapLimitToModule } from 'asap-limit-to';
import { HttpModule } from '@angular/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BlockUIModule } from 'ng-block-ui';
import { NgxMaskModule } from 'ngx-mask';



registerLocaleData(localePt);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export const MY_FORMATS = {
    parse: {
      dateInput: 'YYYY-MM-DD',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'L',
      monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        HttpModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(AppRoutes),
        SharedModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireStorageModule,
        SimpleNotificationsModule.forRoot({
            timeOut: 6000,
            showProgressBar: false,
            pauseOnHover: true,
            clickToClose: true,
            position: ['top', 'right']
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MomentModule,
        NgxMaskModule.forRoot(),
        AsapCrudModule,
        AsapViewerModule,
        AsapLimitToModule
    ],
    declarations: [
        AppComponent,
        SchoolComponent,
        MyPageComponent,
        MessengerComponent,
        PaymentComponent
    ],
    exports: [
        AsapCrudModule,
        SharedModule
    ],
    providers: [
        NotificationsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ClientInterceptor,
            multi: true
        },
        {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
    bootstrap: [ AppComponent ]
})

export class AppModule {
    constructor(public router: Router) {
        localStorage.setItem('client', '3');
        localStorage.setItem('apiURL', environment.apiURL);
        console.log('URL:', window.location.pathname);
        console.log('Carregando AppModule...', new Date());

        const html = document.querySelector('html');
        html.style.setProperty(
          '--main-color-viewer', '#da1d52',
        );
        html.style.setProperty(
          '--bg-btn-viewer', '#da1d52',
        );

        if (window.location.pathname === '/') {
            console.log('Redirecionando para o Portal...', new Date());
            router.navigate(['/portal']);
        }
    }
}
