import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Global } from 'asap-crud';
import { Router } from '@angular/router';

@Injectable()
export class StoreService extends BaseService {

    constructor(
        public http: HttpClient,
        public global: Global,
        public router: Router
    ) {
        super(http,'api/v2','')
    }

    public store(id,page=null) {
        let qryPage = null;
        if (page) {
            qryPage = "page=" + page;
        }
        return this.getResources({router:'client/seller/' + id, query: qryPage});
    } 
    public storeInfo(id) {
        return this.getResources({router:'client/info/vendedor/' + id});
    } 

    public categories() {
        return this.getResources({router:'loja/categoria'});
    }

    public mainStores() {
        return this.getResources({router:'client/loja/abre/' + this.global.clientID() + '/destaque'});
    }

    public spotlightStores(page=null,search="") {
        let qryPage = null;
        if (page) {
            qryPage = "page=" + page;
        }
        if (search != "") {
            if (qryPage != "") qryPage += "&busca=" + search;
        }
        return this.getResources({router:'client/busca/loja/' + this.global.clientID(), query: qryPage});
    }

    public orders(type,search=null) {
        let qry = "";
        if (type == "relatorio") {
            type = "";
        }
        else {
            type = "/" + type;
        }

        let keys = Object.keys(search);
        for (let key of keys) {
            if (search[key] != null) {
                if (qry != "") qry = qry + "&";
                if (search[key] instanceof Date) {
                    qry = qry + key + "=" + search[key].toISOString().substr(0,10);                
                }
                else {
                    qry = qry + key + "=" + search[key];                
                }
            }
        }


        return this.getResources({router:'loja/historico' + type, query: qry});
    }

    public order(id) {
        return this.getResource(null,{router:'loja/detalhado/' + id});
    }

    public statusToID(status) {
        if ((status) && (status != "")) {
            let ids = {
                "PENDENTE":0,
                "ENTREGUE":1,
                "EM TRANSPORTE":2,
                "CANCELADO":3,
                "EM ANDAMENTO":4
            }
            return ids[status.toUpperCase()];
        }
        return null;
    }

}
