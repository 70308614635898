import { Component, OnInit } from '@angular/core';
import { Global } from 'asap-crud';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { BrowserNotificationsService, PushNotification } from 'src/app/services/browser-notification.service';

@Component({
    selector: 'app-messenger-notification',
    templateUrl: './messenger-notification.component.html',
    styleUrls: ['./messenger-notification.component.scss'],
    providers: [ BrowserNotificationsService ]
})
export class MessengerNotificationComponent implements OnInit {


    public users: any;
    constructor(
        public global: Global,
        public firestore: AngularFirestore,
        public notification: BrowserNotificationsService
    ) {
        if (this.global.loggedUser()) {
            this.users = firestore.collection("/history/unread/user_" + this.global.loggedUser().id, (ref) => ref.where('total', '>', 0).limit(5)).valueChanges();
            this.users.subscribe((item:any) => {
                if (item && item.length) {
                    let data = [];
                    let noti:any = {};
    
                    noti.title = item[0].user_name;
                    noti.alertContent = item[0].lastMessage;
                    noti.icon = item[0].picture;
                    
                    console.log("Item",item);
                    data.push(noti);
                    this.notification.generateNotification(data);
                }
            });
        }
    }

    ngOnInit() {
    }

}
